import React from 'react'
import './Playzoneau.css'; 
import atFlg from '../images/atFlg.png'
import back2 from '../images/back2.jpg'
import back3 from '../images/back3.jpg'
import back4 from '../images/back4.jpg'
import back5 from '../images/back5.jpg'
import background from '../images/back7.jpg'
import { Helmet } from 'react-helmet-async';
import favicon from './../images/atFlg.png'

function PlayGroundAt() {
  return (
    <div>
    <Helmet>
        <title>Reisebüro in Österreich</title>
        <meta name="description" content="Reisebüro in Österreich" />
        <link rel="icon" type="image/x-icon" href={favicon} />
    </Helmet>
    <nav class="navbar navbar-expand-lg sticky-top bg-white">
        <div class="container">
            <a class="navbar-brand" href="#"><img src={atFlg} class="top-logo" alt="logo.com"/> <span>Reisebüro in Österreich</span> </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Navigation umschalten">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#home">Startseite</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#list">Liste</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#contact-us">Kontakt</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <section id="home" class="px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform" style={{background:`url(${background})`}}>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
                    <h3 class="fw-bold mb-3 Playfair-font hero-title" style={{color:"white"}}>Besuchen Sie die besten Casino-Hotels in Österreich</h3>
                    <p>Träumen Sie von einem Kurzurlaub, der Wohnzimmerluxus mit Casinoaufregung verbindet? Suchen Sie nicht weiter - Österreich bietet eine Auswahl erstklassiger Casino-Hotels, die eine unvergessliche Reise versprechen. Egal, ob Sie eine exklusive Dachterrasse oder eine luxuriöse Suite mit atemberaubendem Blick suchen, diese sorgfältig ausgewählten Reiseziele bieten die perfekte Kombination aus Luxus, Unterhaltung und der Möglichkeit, Ihr Glück an den Spieltischen zu versuchen.</p>
                </div>
            </div>
        </div>
    </section>

    <section id="list" class="hotel section-padding padding-top-80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-howtouse text-left pb-5">
                        <div class="container-body">
                            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back2} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Ville Resort & Casino</a></h5>
                                            <p class="card-text">Für diejenigen, die sowohl die Ruhe als auch die Aufregung des Spielens schätzen, bietet das Ville Resort & Casino die perfekte Balance. Entspannen Sie sich in minimalistischen, aber stilvollen Zimmern und klären Sie Ihren Geist, bevor Sie in die Welt des Glücksspiels eintauchen. Von 9 bis 17 Uhr erleben Sie den Nervenkitzel von 370 Spielautomaten und über 20 Tischspielen, die während Ihres Aufenthalts endlose Unterhaltung bieten.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back4} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Pullman Reef Hotel Casino</a></h5>
                                            <p class="card-text">Erleben Sie pure Eleganz im Pullman Reef Hotel Casino. Stellen Sie sich vor, Sie entspannen in einem Pool auf dem Dach, umgeben von Palmen, während Sie den atemberaubenden Blick auf das Riff genießen. Die Unterkünfte bieten mehr als nur Komfort und umfassen Zimmer mit Whirlpool und privaten Balkonen mit Panoramablick.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back3} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Star Gold Coast</a></h5>
                                            <p class="card-text">Suchen Sie nach einer königlichen Zuflucht? The Star Gold Coast ist die Antwort. Genießen Sie luxuriöse Suiten, die den Inbegriff von Opulenz darstellen. Jede Suite ist ein Refugium des raffinierten Komforts.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back5} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Crown Melbourne</a></h5>
                                            <p class="card-text">Crown Melbourne heißt Sie mit einer Vielzahl an Optionen willkommen, sei es der Blick auf die Stadt oder das Flusspanorama. Genießen Sie Luxus in prächtigen Villen mit großen Fenstern.</p>
                                            <a href="#" class="list-btn">Jetzt buchen</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Sehr gut</h4>
                                                    <p>100% verifizierte Bewertungen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="contact-us" class="hotel section-padding bg-light">
        <div class="container">
            <div class="row align-items-center">
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="560" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5828.357997772061!2d16.38186146492761!3d48.218539529465744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1saz!2saz!4v1731674246887!5m2!1saz!2saz" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="bg-light p-2 text-center">
        <div class="container padding-20">
            <div class="social-icon">
                <a href="#" class="fa fa-facebook"></a>
                <a href="#" class="fa fa-instagram"></a>
            </div>
            <a href='#' style={{color:"#717171", textDecoration:"none",margin:"10px 0",display:"block"}}>Datenschutzrichtlinie</a>
            <p class="text-dark footer-text">© 2023 Copyright PlayGroundAt
.com Alle Rechte vorbehalten.</p>
        </div>
    </footer>
</div>

  )
}

export default PlayGroundAt

